<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">未绑定设备</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.equipment_number" placeholder="设备编号" />
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select placeholder="请选择" v-model="query.connect_type">
						<a-select-option :value="0" :key="0">
							全部
						</a-select-option>
						<a-select-option :value="2" :key="2">
							4G设备
						</a-select-option>
						<a-select-option :value="1" :key="1">
							WiFi设备
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="在线状态">
					<a-select placeholder="请选择" v-model="query.status">
						<a-select-option :value="1" :key="1">
							在线
						</a-select-option>
						<a-select-option :value="-1" :key="-1">
							离线
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="硬件编号">
					<a-input v-model="query.hardware_number" placeholder="硬件编号" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增</a-button>
			<a-button type="primary" @click="getClassList" style="margin-left: 10px">分类</a-button>
			<a-button style="float: right; margin-right: 10px" @click="toRecovery()"> 回收站 </a-button>
			<a-upload style="float: right; margin-right: 10px" accept=".xlsx,.xls,.cvn" :multiple="false"
				:beforeUpload="beforeUpload" :showUploadList="false" :headers="{
					Authorization: 'Bearer ' + token
				}" @change="handleChange" :action="$domain + '/admin/v1/equipment/equipmentImport'">
				<a-button> <a-icon type="upload" /> 批量导入 </a-button>
			</a-upload>
			<a-button type="primary" style="float: right; margin-right: 15px" @click="toBind()">绑定</a-button>
		</a-row>
		<a-table class="main-table" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
			:pagination="{
				current: query.page,
				total: pagetotal,
				showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
			}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="all_rent_count" slot-scope="text, record">
				<a-space>
					<a @click="toOrder(record)">{{ record.all_rent_count }}次</a>
				</a-space>
			</span>
			<span slot="equipment_status" slot-scope="text, record">
				<a-tag :color="record.equipment_status === 1 ? '#74CE78' : '#757776'">
					{{ record.equipment_status === 1 ? '在线' : '离线' }}
				</a-tag>
			</span>
			<span slot="type" slot-scope="text, record">
				{{ getName(classListNoPage, record.type, "class_id", "name") }}
			</span>
			<span slot="searchTime" slot-scope="text, record">
				{{ tempSearchTimes[record.equipment_id] }} 分钟
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="timeSearch(record)">查找</a>
					<a @click="toEdit(record)">编辑</a>
					<a-popconfirm title="是否刪除?" ok-text="是" cancel-text="否" @confirm="recovery(record.equipment_id)">
						<a style="color: #E0352B">刪除</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
		<UpdateModal ref="updateModal" :equipment_id="equipment_id" :info="equipment_info" :showType="show_type"
			:classList="classListNoPage" @success="getList()" />
		<template>
			<a-modal title="科室绑定" :visible="ks_visible" ok-text="提交" cancel-text="取消" @ok="submitBind"
				@cancel="handleCancelBind">
				<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="bindForm" :model="bindObj"
					:rules="bindRules">
					<a-form-model-item prop="hospital_id" label="请选择医院">
						<a-select placeholder="请选择" @change="hospitalChange" v-model="bindObj.hospital_id">
							<a-select-option :value="item.hospital_id" v-for=" item  in  hospitals "
								:key="item.hospital_id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item prop="department_id" label="请选择科室">
						<a-select v-model="bindObj.department_id" placeholder="请选择">
							<a-select-option :value="item.department_id" v-for=" item  in  departments "
								:key="item.department_id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</template>
		<template>
			<!-- 抽屉2 服务类型 -->
			<a-drawer title="分类列表" :width="550" :visible="visible2" :body-style="{ paddingBottom: '80px' }"
				@close="onClose2">
				<a-table class="main-table" :pagination="{
					current: classQuery.page,
					total: classPagetotal
				}
					" @change="classListChange" :columns="classColumns" :data-source="classList" rowKey="class_id"
					style="margin-top: 14px" v-viewer>
					<span slot="action" slot-scope="text, record">
						<a-space>
							<a @click="toEditClass(record)">编辑</a>
						</a-space>
					</span>
				</a-table>
				<div :style="{
					position: 'absolute',
					right: 0,
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e9e9e9',
					padding: '10px 16px',
					background: '#fff',
					textAlign: 'right',
					zIndex: 1,
				}
					">
					<a-button :style="{ marginRight: '8px' }" @click="onClose2">
						取消
					</a-button>
					<a-button type="primary" @click="onClose2">
						确定
					</a-button>
				</div>
			</a-drawer>


			<a-drawer title="编辑" :width="400" :visible="visible3" :body-style="{ paddingBottom: '80px' }" @close="onClose3">
				<a-form-model ref="classForm" :model="classForm" :rules="rules2" :label-col="labelCol"
					:wrapper-col="wrapperCol">
					<a-form-model-item ref="name" label="名称" prop="name">
						<a-input v-model="classForm.name" @blur="() => {
							$refs.name.onFieldBlur();
						}
							" placeholder="请输入" />
					</a-form-model-item>
				</a-form-model>
				<div :style="{
					position: 'absolute',
					right: 0,
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e9e9e9',
					padding: '10px 16px',
					background: '#fff',
					textAlign: 'right',
					zIndex: 1,
				}
					">
					<a-button :style="{ marginRight: '8px' }" @click="onClose3">
						取消
					</a-button>
					<a-button type="primary" @click="submitClass()">
						确定
					</a-button>
				</div>
			</a-drawer>
		</template>

		<BindModal ref="bindModal" :equipments="selectedRowKeys" @updateData="updateData" />
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import BindModal from './components/bindModal.vue'
import UpdateModal from './components/updateModal.vue'
export default {
	components: { BindModal, UpdateModal },
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			classQuery: {
				page: 1,
				limit: 10
			},
			tempSearchTimes: {},
			show_type: false,
			classPagetotal: 0,
			equipment_id: null,
			equipment_info: {},
			hospitals: [],
			classList: [],
			departments: [],
			visible2: false,
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			equipment: null,
			classListNoPage: [],
			classForm: {
				"name": ""
			},
			columns: [
				{
					title: '序号',
					customRender: (text, record, index) => index + 1
				},
				{
					title: '设备编号',
					dataIndex: 'equipment_number'
				},
				{
					title: '类型',
					scopedSlots: {
						customRender: 'type'
					}
				},
				{
					title: '硬件编号',
					dataIndex: 'hardware_number'
				},
				{
					title: '在线状态',
					scopedSlots: {
						customRender: 'equipment_status'
					}
				},
				// {
				// 	title: '备注',
				// 	dataIndex: 'remark'
				// },
				{
					title: '累计租用次数',
					scopedSlots: {
						customRender: 'all_rent_count'
					}
				},
				{
					title: '查找倒计时',
					scopedSlots: {
						customRender: 'searchTime'
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			rules2: {
				name: [
					{ required: true, message: '请输入名称', trigger: 'blur' },
				],
			},
			data: [],
			selectedRowKeys: [],

			bindRules: {
				department_id: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'blur'
					}
				]
			}
		}
	},
	created() {
		console.log(456)
		this.customer_id = this.$route.query.cid
		this.getList()
		this.getEquipmentClass()
	},
	methods: {
		timeSearch(record) {
			let timeSearch = localStorage.getItem('timeSearch')
			if (timeSearch == null || timeSearch == "") {
				timeSearch = 2
			}
			this.$post('equipment/timeSearch', {
				'equipment_id': record.equipment_id,
				'time': timeSearch,
			})
				.then(res => {
					let { code, msg, data, time } = res
					if (code == 0) {
						this.$message.success("下发" + timeSearch + "分钟成功", 1.5)
						localStorage.setItem(record.equipment_id + 'timeSearch', JSON.stringify({ 'now': time, 'time': timeSearch }))
						localStorage.setItem('timeSearch', timeSearch >= 120 ? 2 : (parseInt(timeSearch) + 2))
						this.getList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		toRecovery() {
			this.$router.push({
				path: '/recoveryList',
			})
		},
		recovery(id) {
			this.$post('equipment/recoveryBin', {
				"equipment_id": id
			})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.getList()
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		toEditClass(record) {
			this.classForm.name = record.name
			this.classForm.class_id = record.class_id
			this.visible3 = true
		},
		submitClass() {
			this.$refs.classForm.validate(valid => {
				if (valid) {
					this.$post('equipmentClass/edit', this.classForm)
						.then(res => {
							let { code, msg, data } = res
							if (code == 0) {
								this.getClassList()
								this.getEquipmentClass()
								this.onClose3()
								this.$message.success(msg, 4)
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		getClassList() {
			this.$post(domain + '/admin/v1/equipmentClass/list', this.classQuery).then(res => {
				if (res.code === 0) {
					this.classList = res.data.list
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				let searId = id.toString()
				const index = arr.findIndex((role) => role[idName] === searId)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		getEquipmentClass() {
			this.$post('equipmentClass/listNoPage', {})
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.classListNoPage = data
						console.log("data", this.classListNoPage)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
		},
		onClose2() {
			this.visible2 = false
		},
		onClose3() {
			this.visible3 = false
			this.classForm = {
				"name": ""
			}
		},
		classListChange(e) {
			this.classQuery.page = e.current
			this.getClassList()
		},
		handleCancel() {
			this.visible = false
			this.initForm()
		},
		beforeUpload(file) {
			this.file = file
			this.uping = true
			this.spinning = true
			console.log('before', file)
		},
		handleChange(e) {
			console.log('改变。。。')
			if (e.file.status == 'done') {
				console.log(e.file.response)
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response
				if (code == 0) {
					this.$message.success(msg, 1.5)
					this.getList()
				} else {
					this.$message.warn(msg, 1.5)
				}
				console.log(data)
			}
		},
		handleCancelBind() {
			this.ks_visible = false
			this.ksInitForm()
		},
		add() {
			this.$refs.updateModal.visible = true
			this.equipment_id = null
			this.show_type = false
			this.equipment_info = {}
		},
		toEdit(item) {
			this.equipment_info = item
			this.equipment_id = item.equipment_id
			this.show_type = item.equipment_id != null && item.equipment_number != null && item.equipment_number != ''
			this.$refs.updateModal.visible = true
		},
		allImport() { },
		initForm() {
			this.equipment_info = {}
			this.equipment_id = null
		},
		ksInitForm() {
			this.bindObj = {}
		},

		submitBind() {
			this.$refs.bindForm.validate(valid => {
				if (valid) {
					let obj = {
						department_id: this.bindObj.department_id,
						equipment_ids: this.selectedRowKeys
					}
					this.$post('equipment/equipmentBind', obj)
						.then(res => {
							let { code, msg, data } = res
							if (code == 0) {
								this.$message.success(msg, 4)
								this.ks_visible = false
								this.ksInitForm()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		hospitalChange(name, obj) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: obj.data.key,
				limit: 10000,
				page: 1
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toBind() {
			if (this.selectedRowKeys.length == 0) {
				this.$message.error('请先选择要绑定的设备', 1.5)
			} else {
				// this.ks_visible = true
				// this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
				// 	if (res.code === 0) {
				// 		this.hospitals = res.data
				// 	} else {
				// 		this.$message.error(res.msg)
				// 	}
				// })

				this.$refs.bindModal.visible = true
			}
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/equipment/equipmentListUnbind', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.data
					this.pagetotal = res.data.total
					this.tempSearchTimes = {}
					this.initSearchTime()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		},
		toOrder(item) {
			if (item.all_rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						eid: item.equipment_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			console.log('选中中', selectedRowKeys)
			this.selectedRowKeys = selectedRowKeys
		},
		updateData() {
			this.selectedRowKeys = []
			this.handleSearch()
		},
		getTargetTimestamp(equipmentId) {
			// 根据设备ID获取目标时间戳的方法
			// 这里假设为 getTargetTimestampById(equipmentId)

		},
		searchTime(equipment_id) {
			let obj = localStorage.getItem(equipment_id + 'timeSearch')
			let time = 0
			let returnTime = 0
			let nowTime = Date.now() / 1000;
			if (obj != null && obj != "") {
				time = JSON.parse(obj)["time"]
				returnTime = Math.floor(time - (nowTime - JSON.parse(obj)["now"]) / 60)
				if (returnTime <= 0) {
					localStorage.removeItem(equipment_id + 'timeSearch')
				}
				console.log(returnTime)
			}
			this.$set(this.tempSearchTimes, equipment_id, returnTime);
			console.log(this.tempSearchTimes)
			return returnTime
		},
		initSearchTime() {
			for (let i = 0; i < this.data.length; i++) {
				this.$set(this.tempSearchTimes, this.data[i].equipment_id, this.searchTime(this.data[i].equipment_id));
				console.log(this.tempSearchTimes)
			}
		}
	},
	computed: {
		token() {
			return this.$store.state.token
		},
	},
	mounted() {
		this.timer = setInterval(() => {
			for (let i = 0; i < this.data.length; i++) {
				this.$set(this.tempSearchTimes, this.data[i].equipment_id, this.searchTime(this.data[i].equipment_id));
				console.log(this.tempSearchTimes)
			}
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.timer); // 清除定时器
	},
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
